<template>
  <div class="container">
    <StandupParticipants
      :participants="participants"
      @removeParticipant="removeParticipant"
      @addParticipant="addParticipant" />
    <div class="mainSection" :class=" { overTime: timeInSeconds > 59 } ">
      <h1>Standup Timer</h1>
      <BigCount :timeInSeconds="timeInSeconds" />
      <div class="activeParticipant" v-if="activeParticipant">{{ activeParticipant.name }}</div>
      <div class="buttons">
        <button @click="resetCounter" v-if="!counterRunning && timeInSeconds > 0">
          Reset
        </button>
        <button @click="stopCounter" v-if="counterRunning">
          Stop
        </button>
        <button @click="startCounter" v-else>
          Start
        </button>
        <button @click="nextParticipant" v-if="(counterRunning || timeInSeconds > 0) && activeParticipant">
          Next Participant
        </button>
        </div>
    </div>
  </div>
</template>

<script>
import BigCount from './components/BigCount.vue'
import StandupParticipants from './components/StandupParticipants.vue'

export default {
  name: 'App',
  components: {
    BigCount,
    StandupParticipants
  },
  data() {
    return {
      timeInSeconds: 0,
      interval: null,
      counterRunning: false,
      initialParticipants: ['Hello'],
      participants: [],
      localStorage: window.localStorage
    }
  },
  mounted() {
    this.initialParticipants = JSON.parse(this.localStorage.getItem('initialParticipants'));

    const shuffledParticipants = this.shuffle(this.initialParticipants)

    shuffledParticipants.forEach(participant => {
      this.participants.push({
        name: participant,
        done: false
      })
    })
  },
  computed: {
    activeParticipant() {
      return this.participants.find(participant => participant.done === false) || null
    },
  },
  methods: {
    incrementTimer() {
      this.timeInSeconds++
    },
    startCounter() {
      this.timeout = setInterval(this.incrementTimer, 1000)
      this.counterRunning = true
    },
    stopCounter() {
      clearInterval(this.timeout)
      this.counterRunning = false
    },
    resetCounter() {
      this.timeInSeconds = 0
    },
    restartCounter() {
      this.stopCounter()
      this.timeInSeconds = 0
      this.startCounter()
    },
    nextParticipant() {
      this.activeParticipant.time = this.timeInSeconds
      this.activeParticipant.done = true
      if (this.activeParticipant) {
        this.restartCounter()
      } else {
        this.stopCounter();
        this.resetCounter();
      }
    },
    addParticipant(participantName) {
      this.participants.push({
        name: participantName,
        done: false,
      })

      this.setInititialParticipants();
    },
    removeParticipant(index) {
      this.participants.splice(index, 1)
      this.setInititialParticipants()
    },
    setInititialParticipants() {
      this.initialParticipants = this.participants.map(participant => participant.name);
      this.localStorage.setItem('initialParticipants', JSON.stringify(this.initialParticipants));
    },
    shuffle(array) {
      let currentIndex = array.length,  randomIndex;
      while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      }

      return array;
    }
  }
}
</script>

<style>
body, #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  padding: 0;
  margin: 0;
}

.container {
  width: 100vw;
  height: 100vh;
  margin: 0;
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  color: #555;
  transition: 1s ease all;
}

.mainSection {
  width: 100%;
}

.overTime {
  background-color: #c12f2f;
  color: #ffffff;
}

.activeParticipant {
  font-size: 24px;
}

.buttons {
  margin-top: 40px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
</style>
