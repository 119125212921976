<template>
  <div class="hello">
    <span
      class="number"
      id="seconds"
      v-show="minutes > 0">
      {{ minutes }}:</span>
    <span
      class="number"
      id="pad-zero"
      v-show="minutes > 0 && remainderSeconds < 10">0</span>
    <span class="number" id="seconds">
      {{ remainderSeconds }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BigCount',
  props: {
    timeInSeconds: Number
  },
  computed: {
    minutes() {
      return Math.floor(this.timeInSeconds / 60)
    },
    remainderSeconds() {
      return this.timeInSeconds % 60
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.number {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 200px;
  font-variant-numeric: tabular-nums;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
