<template>
  <div class="people">
    <div class="addParticipant">
      <input type="text" v-model="addParticipantName" />
      <button @click="addParticipant">Add</button>
    </div>
    <div v-for="(person, index) in participants" :key="person" class="participant">
      {{ person.name }}<span v-if="person.done"> - Done ({{person.time}}s)</span>
      <button @click="$emit('removeParticipant', index)">Remove</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StandupParticipants',
  props: {
    participants: Array
  },
  data() {
    return {
      addParticipantName: '',
    }
  },
  computed: {
    minutes() {
      return Math.floor(this.timeInSeconds / 60)
    },
    remainderSeconds() {
      return this.timeInSeconds % 60
    },
  },
  methods: {
    addParticipant() {
      if (this.addParticipantName.length) {
        this.$emit('addParticipant', this.addParticipantName)
        this.addParticipantName = ''
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.people {
  text-align: left;
  width: 300px;
  height: 100vh;
  top: 0;
  left: 0;
  background: #eee;
  flex-shrink: 0;
}

.participant {
  font-weight: bold;
  padding: 30px;
  padding-bottom: 0;
}

.participant button {
  margin-left: 10px;
}

.addParticipant {
  background: #999;
  padding: 30px;
}

.addParticipant button {
  margin-left: 10px;
}
</style>
